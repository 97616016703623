import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Card, Props as CardProps } from './Card'

export interface Props {
  cards: CardProps[]
}

export const CardsList = memo(function CardsList({ cards }: Props) {
  if (!cards) {
    return null
  }

  if (cards.length < 1) {
    return null
  }

  return (
    <Container>
      <Wrapper row wrap>
        {cards.map((item, index) => (
          <Card key={index} {...item} />
        ))}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 1.25rem 6.111vw 0;

  @media (max-width: 1199px) {
    padding: 0 1.5rem 6.25rem;
  }
`

const Wrapper = styled(FlexBox)`
  margin-right: -4rem;

  @media (max-width: 1199px) {
    margin-right: 0;
  }
`
