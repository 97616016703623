import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Wave } from 'app/components/Icons'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  image?: ImageProps
  title?: string
  URL?: string
}

export const Card = memo(function Card({
  description,
  image,
  title,
  URL,
}: Props) {
  const EXTERNAL_URL_REGEX = new RegExp('^(https?|ftp|www)://')
  const externalURL = URL && EXTERNAL_URL_REGEX.test(URL)

  const componentDOM = (
    <>
      <Head>
        <Wave />

        {image ? (
          <>
            <Thumb>
              <LazyLoadComponent>
                <Image {...image} />
              </LazyLoadComponent>
            </Thumb>

            <Background>
              <LazyLoadComponent>
                <Image {...image} />
              </LazyLoadComponent>
            </Background>
          </>
        ) : null}
      </Head>

      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}

      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}
    </>
  )

  return (
    <>
      {URL ? (
        externalURL ? (
          <ExternalLink href={URL} rel="noopener" target="_blank">
            {componentDOM}
          </ExternalLink>
        ) : (
          <Container to={URL || ''}>{componentDOM}</Container>
        )
      ) : (
        <Static>{componentDOM}</Static>
      )}
    </>
  )
})

const Style = css`
  display: inline-block;
  width: calc(50% - 4rem);
  margin: 6.25rem 4rem 0 0;

  @media (max-width: 1199px) {
    width: 100%;
    margin: 5rem 0 0;
  }
`

const ExternalLink = styled.a`
  ${Style}
`

const Container = styled(Link)`
  ${Style}
`

const Static = styled.div`
  ${Style}
`

const Head = styled.div`
  height: 44.4vh;
  max-height: 25rem;
  margin-bottom: 2.5rem;
  position: relative;

  > svg {
    width: 100%;
    height: 3.125rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight5};
    opacity: 0.3;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  }

  > div {
    overflow: hidden;
    position: absolute;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    height: 20rem;
    margin-bottom: 1.875rem;

    > svg {
      display: none;
    }
  }
`

const Background = styled.div`
  width: calc(100% - 1.875rem);
  height: calc(100% - 3.75rem);
  top: 1.875rem;
  left: 1.875rem;
  z-index: 2;

  @media (max-width: 1199px) {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`

const Thumb = styled.div`
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralLight3, 0.4)},
      ${rgba(theme.colors.variants.neutralLight3, 1)}
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2.5625rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 2rem;
  margin-top: 1.25rem;
`
